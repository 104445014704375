
a{
display: block;
}

.Footer{
  position: fixed;
  bottom: 0px;
  width: 100%;
}

a:link {
  text-decoration: none;
}