.CookieNotice{
    position: fixed;
    top: 0px;
    width: 100%;
    border: solid 1px;
   
}
h1{
    color: white;
    margin-left: auto;
    margin-right: auto;
}
